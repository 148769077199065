import { render, staticRenderFns } from "./UserTrainingWeek.vue?vue&type=template&id=30c06c97&scoped=true"
import script from "./UserTrainingWeek.vue?vue&type=script&lang=js"
export * from "./UserTrainingWeek.vue?vue&type=script&lang=js"
import style0 from "./UserTrainingWeek.vue?vue&type=style&index=0&id=30c06c97&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c06c97",
  null
  
)

export default component.exports