<template>
  <div class="py-1">
    <v-scroll-x-transition mode="in-out" hide-on-leave>
      <v-skeleton-loader type="heading" v-show="loading"></v-skeleton-loader>
    </v-scroll-x-transition>

    <v-scroll-x-transition mode="in-out" hide-on-leave>
      <v-row align="center" no-gutters v-show="!loading">
        <div class="d-flex flex-row align-center w-100">
          <div
            v-for="(day, index) in pastSevenDays"
            :key="index"
            class="d-flex flex-column align-center mx-1"
          >
            <v-avatar
              size="20"
              :color="getDayColor(day)"
              class="elevation-1 rounded-lg caption"
            >
              <span>{{ day.date() }}</span>
            </v-avatar>
          </div>
        </div>
      </v-row>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import moment from 'moment';
import { getFirestore, collection, query, where, orderBy, getDocs, getDoc, doc } from 'firebase/firestore';

export default {
  name: 'UserTrainingWeek',
  props: {
    userId: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      trainedDays: [],
      scheduledDays: [],
      loading: true,
      planType: null,
    };
  },
  computed: {
    // Generate an array of 7 dates: today and the previous 6 days
    pastSevenDays() {
      const days = [];
      for (let i = 0; i < 7; i++) {
        // i=0: Today, i=1: Yesterday, etc.
        days.push(moment().subtract(i, 'days').startOf('day'));
      }
      return days
      // reversed so it goes oldest to newest left to right
      // remove .reverse() if you want newest on the left.
    },
  },
  methods: {
    dayString(mDate) {
      return mDate.format('YYYY-MM-DD');
    },
    getDayColor(day) {
      const dayStr = this.dayString(day);
      if (this.trainedDays.includes(dayStr)) {
        // User completed workout that day
        return 'green';
      } else if (this.scheduledDays.includes(dayStr)) {
        // Scheduled but not completed
        return 'error';
      }
      // Neither scheduled nor completed
      return 'grey';
    },
    async fetchPlanification() {
      const db = getFirestore();
      const planId = this.user?.mainWorkout?.plan_id;
      if (!planId) {
        // If there's no plan_id, just continue
        return;
      }

      const planRef = doc(db, 'planifications', planId);
      const planSnap = await getDoc(planRef);
      if (planSnap.exists()) {
        const planData = planSnap.data();
        this.planType = planData.type;

        if (this.planType === 'schedule') {
          await this.fetchScheduledWorkouts(planId);
        }
      }
    },
    async fetchScheduledWorkouts(planId) {
      // For a schedule planification, we have a subcollection "workouts"
      // Each doc in that subcollection is named YYYY-MM-DD
      const db = getFirestore();

      // We'll just try to get each day's document individually.
      // Alternatively, we could fetch all and filter, but this approach is straightforward.
      const tasks = this.pastSevenDays.map(async (day) => {
        const dayStr = this.dayString(day);
        const docRef = doc(db, 'planifications', planId, 'workouts', dayStr);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // If there's a doc for that day, it's a scheduled workout day
          this.scheduledDays.push(dayStr);
        }
      });

      await Promise.all(tasks);
    },
    async fetchUserWorkouts() {
      this.loading = true;
      this.trainedDays = [];
      const db = getFirestore();
      const startOfRange = this.pastSevenDays[0].toDate(); 
      const endOfRange = moment().endOf('day').toDate();

      const workoutsRef = collection(db, 'workouts');
      const q = query(
        workoutsRef,
        where('user_id', '==', this.$props.userId),
        where('date', '>=', startOfRange),
        where('date', '<=', endOfRange),
        orderBy('date', 'asc')
      );

      try {
        const snapshot = await getDocs(q);
        const workouts = snapshot.docs.map(doc => doc.data());
        const daysSet = new Set();
        for (let workout of workouts) {
          const workoutDayStr = moment(new Date(workout.date.seconds * 1000)).format('YYYY-MM-DD');
          daysSet.add(workoutDayStr);
        }
        this.trainedDays = Array.from(daysSet);
      } catch (error) {
        console.error('Error fetching user workouts for last 7 days:', error);
      }
    },
  },
 async  mounted() {
    this.loading = true;
        await this.fetchUserWorkouts();
        await this.fetchPlanification();
        this.loading = false;
  },
  watch: {
    'user.mainWorkout': {
      async handler() {
        this.loading = true;
        //reset the data
        this.trainedDays = [];
        this.scheduledDays = [];
        this.planType = null;


        await this.fetchUserWorkouts();
        await this.fetchPlanification();
        this.loading = false;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* Adjust any custom styling here if needed */
</style>
